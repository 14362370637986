'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('finding', {
        url: '/blade/:blade/finding/:id',
        template: '<finding></finding>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
